import logo from "./logo.svg";
import { useState, useEffect, useDisclosure, useRef } from "react";
import "./App.css";
import {
  Heading,
  Divider,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Spacer,
  Center,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Text
} from "@chakra-ui/react";

function App() {
  const [data, setData] = useState({ coins: [], transactions: [] });
  const [loaded, setLoaded] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const cancelRef = useRef();
  const [coin, setCoin] = useState("");
  const [updown, setUpdown] = useState("increase");
  const [CoinName, setCoinName] = useState("");
  const [CoinSymbol, setCoinSymbol] = useState("");
  const [Purchased, setPurchased] = useState("");
  const [PurchasedDate, setPurchasedDate] = useState("");
  const [PurchasedAmount, setPurchasedAmount] = useState("");
  const [PurchasedPrice, setPurchasedPrice] = useState("");
  const [SoldDate, setSoldDate] = useState("");
  const [SoldAmount, setSoldAmount] = useState("");
  const [SoldPrice, setSoldPrice] = useState("");
  const [Notes, setNotes] = useState("");
  const [Update, setUpdate] = useState("no");
  const [form, setForm] = useState({coin:'', symbol:'', purchased:'', purchaseddate:'',purchasedprice:'', purchasedamount:'',solddate:'',soldamount:'',soldprice:'',notes:''})
  const [Hide, setHide] = useState("block");

  var CN = '';
  var CS = '';
  var P = '';
  var PD = '';
  var PA = '';
  var PP = '';
  var SD = '';
  var SA = '';
  var SP = '';
  var N = '';
  var U = '';

  const [URL, setURL] = useState(`https://boring-einstein-ac94ff.netlify.app/?coinName=${CN}&coinSymbol=${CS}&purchased=${P}&purchasedDate=${PD}&purchasedAmount=${PA}&purchasedPrice=${PP}&soldDate=${SD}&soldAmount=${SA}&soldPrice=${SP}&notes=${N}&update=${U}`);

  useEffect(() => {
    fetch(
    //   "https://script.google.com/macros/s/AKfycbxHNR3XbEKqX5RwaA4IQWYObdJJKqYIGDsS1b-cEaa5qS2SzSsyLybhqNvBr2Q3dY_7VQ/exec",
      "https://script.google.com/macros/s/AKfycbwKqV5wN2oebJqkjXKHJIxmas8Unna9grtpL4uaZnbhMS5u5HOBPLCMh6WHOFZBkfAl/exec",
      {
        method: "POST",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res2) => {
        console.log(res2);
        setData(res2);

      });

      window.addEventListener('message', (e) => {
        // console.log(e);
        if(e.data.message !== null && e.data.message !== undefined){
          if(e.data.message == 'saved'){
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
          if(e.data.message == 'close'){
            setisOpen(false);
          }
        }
      })
  }, []);

  function onClose() {
    setisOpen(false);
  }

  function formatDate(date) {
    var d = date,
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = "" + date.getFullYear();

    if (month.length > 2) {
      month = "0" + month;
    }
    if (day.length > 2) {
      day = "0" + day;
    }
    var new_date = `${year}-${month}-${day}`;
    // console.log(new_date);
    return new_date;
  }

  function table_data() {
    var table_content = [];
    var UPL = 0;
    var RPL = 0;
    var P_A = 0;
    var S_A = 0;
    for (let i = 0; i < data.coins.length; i++) {
      table_content.push(
        <Tr
          onClick={() => {
            coinClick(data.coins[i].name);
          }}
          key={i}
          className="tr_elems"
        >
          <Td >{data.coins[i].rank}</Td>
          <Td>
            <Link
              isExternal
              href={
                "https://coinmarketcap.com/currencies/" +
                data.coins[i].name.replaceAll(" ", "-")
              }
            >
              {data.coins[i].name}
            </Link>
          </Td>
          <Td>{data.coins[i].symbol}</Td>
          <Td>
            ${parseFloat(data.coins[i].price) < 1
              ? parseFloat(data.coins[i].price).toFixed(5).toLocaleString()
              : parseFloat(data.coins[i].price).toFixed(2).toLocaleString()}
          </Td>

          {extraData(data.coins[i].name, parseFloat(data.coins[i].price), UPL, RPL, P_A, S_A)}
        </Tr>
      );
    }

    return table_content;
  }

  function changeForm (e) {
    console.log(form);
    console.log(e.target.name);
    console.log(e.target.value);
    var eleme = (form[e.target.name] = e.target.value);
    setForm(eleme);
  }

  function extraData (coin, cprice, UPL, RPL, P_A, S_A) {
      var f_elem;
      for(let i=0; i<data.transactions.length; i++){
        if(data.transactions[i].name.toLowerCase() == coin.toLowerCase()){
          f_elem = data.transactions[i];
          break;
        }
      }
      if(f_elem !== null && f_elem !== undefined){
        return <><Td>{f_elem.purchased}</Td>
        <Td>{formatDate(new Date(f_elem.purchased_date))}</Td>
        <Td>${parseFloat(f_elem.purchased_price).toFixed(2).toLocaleString()}</Td>
        <Td>${f_elem.purchased_amount}</Td>
        <Td>${((parseFloat(f_elem.purchased)*cprice) - (parseFloat(f_elem.purchased)*parseFloat(f_elem.purchased_price))).toFixed(2).toLocaleString()}</Td>
        <Td>{f_elem.alert == 'yes' ? f_elem.alert : formatDate(new Date(f_elem.alert))}</Td>
        <Td>{f_elem.sold_date !== '' ? formatDate(new Date(f_elem.sold_date)) : 'None'}</Td>
        <Td>${f_elem.sold_price !== '' ? parseFloat(f_elem.sold_price).toFixed(2).toLocaleString() : 0}</Td>
        <Td>{f_elem.sold_amount !== '' ? f_elem.sold_amount : 0}</Td>
        <Td>${isNaN(((parseFloat(f_elem.sold_amount)*parseFloat(f_elem.sold_price)) - (parseFloat(f_elem.purchased)*parseFloat(f_elem.purchased_price))).toFixed(2).toLocaleString()) ? 0 : ((parseFloat(f_elem.sold_amount)*parseFloat(f_elem.sold_price)) - (parseFloat(f_elem.purchased)*parseFloat(f_elem.purchased_price))).toFixed(2).toLocaleString()}</Td>
        <Td>{f_elem.notes}</Td>
        </>
      } else {
        return <>
        <Td>0</Td>
        <Td>None</Td>
        <Td>$0</Td>
        <Td>$0</Td>
        <Td>$0</Td>
        <Td>None</Td>
        <Td>None</Td>
        <Td>$0</Td>
        <Td>0</Td>
        <Td>$0</Td>
        <Td></Td>
        </>
      }
  }

  function tFooter () {
    var P_A = 0;
    var S_A = 0;
    var UPL = 0;
    var RPL = 0;
    console.log(data.transactions);
    console.log(data.coins);
    for(let i=0; i<data.transactions.length; i++){
      P_A += parseFloat(data.transactions[i].purchased_amount);
      S_A += parseFloat(data.transactions[i].sold_amount);
      console.log(P_A);
      for(let j=0; j<data.coins.length; j++){
        if(data.transactions[i].name.toLowerCase() == data.coins[j].name.toLowerCase()){
          RPL += ((parseFloat(data.transactions[i].sold_amount)*parseFloat(data.transactions[i].sold_price)) - (parseFloat(data.transactions[i].purchased)*parseFloat(data.transactions[i].purchased_price)));
          UPL += ((parseFloat(data.transactions[i].purchased)*parseFloat(data.coins[j].price)) - (parseFloat(data.transactions[i].purchased)*parseFloat(data.transactions[i].purchased_price)))
          break;
        }
      }
    }
      console.log(P_A)
      console.log(UPL);
      return <>
      <Th fontWeight="bold">Total:</Th>
      <Th></Th>
      <Th></Th>
      <Th></Th>
      <Th></Th>
      <Th></Th>
      <Th></Th>
      <Th>${isNaN(P_A.toFixed(2).toLocaleString()) ? 0 : P_A.toFixed(2).toLocaleString()}</Th>
      <Th>${isNaN(UPL.toFixed(2).toLocaleString()) ? 0 : UPL.toFixed(2).toLocaleString()}</Th>
      <Th></Th>
      <Th></Th>
      <Th></Th>
      <Th>${isNaN(S_A.toFixed(2).toLocaleString()) ? 0 : S_A.toFixed(2).toLocaleString()}</Th>
      <Th>${isNaN(RPL.toFixed(2).toLocaleString()) ? 0 : RPL.toFixed(2).toLocaleString()}</Th>
      <Th></Th>
</>
  }

  function coinClick(coin) {
    setHide('block');
    console.log(coin);
    setCoinName('');
    setCoinSymbol('');
    setPurchased('');
    setPurchasedAmount('');
    setPurchasedDate('');
    setPurchasedPrice('');
    setSoldPrice('');
    setSoldDate('');
    setSoldAmount('');
    setNotes('');
     CN = '';
     CS = '';
     P = '';
     PD = '';
     PA = '';
     PP = '';
     SD = '';
     SA = '';
     SP = '';
     N = '';
    if(coin !== undefined && coin !== null && typeof(coin) === 'string'){
      setCoin(coin);
      var fou = false;
      for(let i=0; i<data.transactions.length; i++){
        if(data.transactions[i].name.toLowerCase() == coin.toLowerCase()){
          fou = true;
          setCoinName(data.transactions[i].name);
          setCoinSymbol(data.transactions[i].symbol);
          setPurchased(data.transactions[i].purchased);
          setPurchasedAmount(data.transactions[i].purchased_amount);
          setPurchasedDate(formatDate(new Date(data.transactions[i].purchased_date)));
          setPurchasedPrice(data.transactions[i].purchased_price);
          setSoldPrice(data.transactions[i].sold_price);
          setSoldDate(data.transactions[i].sold_date !== '' ? formatDate(new Date(data.transactions[i].sold_date)) : '');
          setSoldAmount(data.transactions[i].sold_amount);
          setNotes(data.transactions[i].notes);
           CN = data.transactions[i].name;
           CS = data.transactions[i].symbol;
           P = data.transactions[i].purchased;
           PD = formatDate(new Date(data.transactions[i].purchased_date));
           PA = data.transactions[i].purchased_amount;
           PP = data.transactions[i].purchased_price;
           SD = data.transactions[i].sold_date !== '' ? formatDate(new Date(data.transactions[i].sold_date)) : '';
           SA = data.transactions[i].sold_amount;
           SP = data.transactions[i].sold_price;
           N = data.transactions[i].notes;
          break;
        }
      }
      if(fou){
        setUpdate('yes');
        U = 'yes';
      } else {
        for(let i=0; i<data.coins.length; i++){
          if(data.coins[i].name.toLowerCase() == coin.toLowerCase()){
            console.log('updating');
            setCoinName(data.coins[i].name);
            setCoinSymbol(data.coins[i].symbol);
            setPurchasedPrice(data.coins[i].price);
            setPurchased('');
          setPurchasedAmount('');
          setPurchasedDate('');
          setSoldPrice('');
          setSoldDate('');
          setSoldAmount('');
          setNotes('');
           CN = data.coins[i].name;
           CS = data.coins[i].symbol;
           PP = data.coins[i].price;
           PD = '';
              PA = '';
     P = '';
     SD = '';
     SA = '';
     SP = '';
     N = '';
            break;
          }
        }
        setUpdate('no');
        U = 'no';

      }
    } else {
      setCoin('');
      setUpdate('no');
      U = 'no';
    }
    setURL(`https://boring-einstein-ac94ff.netlify.app/?coinName=${CN}&coinSymbol=${CS}&purchased=${P}&purchasedDate=${PD}&purchasedAmount=${PA}&purchasedPrice=${PP}&soldDate=${SD}&soldAmount=${SA}&soldPrice=${SP}&notes=${N}&update=${U}`);
console.log(`https://boring-einstein-ac94ff.netlify.app/?coinName=${CN}&coinSymbol=${CS}&purchased=${P}&purchasedDate=${PD}&purchasedAmount=${PA}&purchasedPrice=${PP}&soldDate=${SD}&soldAmount=${SA}&soldPrice=${SP}&notes=${N}&update=${U}`);
    setTimeout(() => {
      setisOpen(true);

    }, 1000);

  }

  function Save () {
    if(CoinName !== '' && CoinSymbol !== '' && Purchased !== '' && PurchasedAmount !== '' && PurchasedDate !== '' && PurchasedPrice !== ''){
      onClose();
      // console.log(`https://script.google.com/macros/s/AKfycbw_THqG6pqCVoZvXMPoFCfDpIlnPuO_aP23rNtO6MMsA7xjj0II5A9IrCo44T4DXGSnoQ/exec?coinName=${CoinName}&coinSymbol=${CoinSymbol}&purchased=${Purchased}&purchasedDate=${PurchasedDate}&purchasedAmount=${PurchasedAmount}&purchasedPrice=${PurchasedPrice}&soldDate=${SoldDate}&soldAmount=${SoldAmount}&soldPrice=${SoldPrice}&notes=${Notes}&update=${Update}`)
      fetch(`https://script.google.com/macros/s/AKfycbw2wyJRE6Cvm6KsySkve8bVIbmQmP55EpJZGVhicN0IZcrVQqaA_3RbZQhncm4KO3bd9w/exec?coinName=${CoinName}&coinSymbol=${CoinSymbol}&purchased=${Purchased}&purchasedDate=${PurchasedDate}&purchasedAmount=${PurchasedAmount}&purchasedPrice=${PurchasedPrice}&soldDate=${SoldDate}&soldAmount=${SoldAmount}&soldPrice=${SoldPrice}&notes=${Notes}&update=${Update}`).then((res) => { return res.text(); }).then((res2) => {
        console.log(res2);
        if(res2 === 'saved'){
          setCoinName('');
          setCoinSymbol('');
          setPurchased('');
          setPurchasedAmount('');
          setPurchasedDate('');
          setPurchasedPrice('');
          setSoldPrice('');
          setSoldDate('');
          setSoldAmount('');
          setNotes('');
          window.location.reload();
        }
      })
    }
  }

  return (
    <div className="App">

      <Flex>
        <Box w="80%">
        <Heading size="lg" mt="3" mb="5" marginLeft="10rem" fontSize="50px">
        CoinMarketCap Dashboard
      </Heading>
        </Box>
        <Box w="20%">
        <Button colorScheme="messenger" mt="6" mb="5" onClick={coinClick}>Add Transaction</Button>
        </Box>
  </Flex>
      <Box w="95%" justifyContent="center" p={4} className="box">
        <Table variant="striped" colorScheme="blue" size="sm">
          <TableCaption>CryptoCurrencies Updated Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Rank</Th>
              <Th>Name</Th>
              <Th>Symbol</Th>
              <Th>Price</Th>
              <Th>Qunatity</Th>
              <Th>Purchased Date</Th>
              <Th>Purchased Price</Th>
              <Th>Purchased $ Amount</Th>
              <Th>Unrealised P&L</Th>
              <Th>Alert Date</Th>
              <Th>Sold Date</Th>
              <Th>Sold Price</Th>
              <Th>Sold $ Amount</Th>
              <Th>Realised P&L</Th>
              <Th>Notes</Th>
            </Tr>
          </Thead>
          <Tbody textAlign="center">{data.coins.length > 0 ? table_data() : <Box width="1500%" justifyContent="center"> <Spinner
  thickness="16px"
  speed="1s"
  emptyColor="gray.200"
  color="green.500"
  size="xl"
  width="10rem"
  height="10rem"
  textAlign="center"
  mt="3rem"
/> </Box>}</Tbody>
<Tfoot>
  <Tr>{tFooter()}</Tr>

  </Tfoot>
        </Table>
      </Box>
      <Modal
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        size="xl"
        className="modalbox"
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent className="modalbox">

          <iframe src={URL} className="iframeApp"  ></iframe>

        </ModalContent>
      </Modal>
    </div>
  );
}

export default App;
